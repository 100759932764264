import Alert from './Alert/Alert';

function Alerts(props) {
    return (

        <>
            <div className={`alerts-container ${props.isAlert && 'show-alert'}`}>
                <Alert type={props.type} code={props.code}>{props.msg}</Alert>
            </div>
        </>

    );
}

export default Alerts;