import React from 'react';

const TitreH6 = (props) => {
    const classes = `${props.className}`;

    return(
        <>
            <h6 className={classes}>{props.children}</h6>
        </>
    );
}

export default TitreH6;