import '../../assets/css/StatsModal.css';

function StatsModal(props) {
    return (

        <>
            <div id="StatsModal" className="modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">All "Normal" stats for {props.player}</h5>
                            <button type="button" className="btn-close" onClick={props.click}>
                            <span aria-hidden="true"></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default StatsModal;