import { useEffect, useState } from 'react';
import '../../../assets/css/Map.css';
import Profile from '../../Profiles/Profile/Profile';

function Map(props) {
    const [mostPlayed, setMostPlayed] = useState();
    const [messagePlayed, setMessagePlayed] = useState();
    const [typeMessagePlayed, setTypeMessagePlayed] = useState();
    const [ProfileMessagePlayed, setProfileMessagePlayed] = useState();

    useEffect(() => {
        setMostPlayed(props.mostPlayedMap);
        setMessagePlayed(decideMessage()[1]);
        setTypeMessagePlayed(decideMessage()[2]);
        setProfileMessagePlayed(decideMessage()[0]);
    }, [props])

    function decideMessage() {
        let messageToDisplay = "";
        let typeToDisplay = "light";
        let profileToDisplay = "";
        
        if(props.mostPlayedMap) {
            switch (props.mostPlayedMap) {
                case "paramo":
                    profileToDisplay = "Aventurer";
                    messageToDisplay = "That's what being original is !";
                    typeToDisplay = "success";
                  break;
                case "miramar":
                    profileToDisplay = "Tryharder";
                    messageToDisplay = "Damn. That's original ! Nope. It's not.";
                    typeToDisplay = "danger";
                  break;
                case "vikendi":
                    profileToDisplay = "Snowboarder";
                    messageToDisplay = "Well, it's cold and risky with bears, but it's fine !";
                    typeToDisplay = "info";
                  break;
                case "erangel":
                    profileToDisplay = "Tryharder";
                    messageToDisplay = "Damn. That's original ! Nope. It's not.";
                    typeToDisplay = "danger";
                  break;
                case "haven":
                    profileToDisplay = "Aventurer";
                    messageToDisplay = "That's what being original is !";
                    typeToDisplay = "success";
                  break;
                case "deston":
                    profileToDisplay = "Aventurer";
                    messageToDisplay = "That's what being original is !";
                    typeToDisplay = "success";
                  break;
                case "jackal":
                    profileToDisplay = "Trainee";
                    messageToDisplay = "Really ? Stop training and fight people. That's what the game is.";
                    typeToDisplay = "danger";
                  break;
                case "sanhok":
                    profileToDisplay = "Borderline";
                    messageToDisplay = "Well. That could have been Erangel or Miramar.";
                    typeToDisplay = "warning";
                  break;
                case "karakin":
                    profileToDisplay = "Aventurer";
                    messageToDisplay = "That's what being original is !";
                    typeToDisplay = "success";
                  break;
                case "taego":
                    profileToDisplay = "Borderline";
                    messageToDisplay = "Well. That could have been Erangel or Miramar.";
                    typeToDisplay = "warning";
                  break;
                default:
                  break;
              }
        }

        return ([profileToDisplay, messageToDisplay, typeToDisplay]);
    }

    return (

        <>
            {(mostPlayed && mostPlayed !== undefined) && 
                <>
                    <div className='MapMostPlayed fullwidth'>
                        <div className='MapMostPlayed--bg w-100 h-100' style={{backgroundImage: `url(${require(`../../../assets/images/maps/${mostPlayed}.jpg`)})`}}></div>
                        <div className='d-flex flex-column justify-content-between align-items-center gap-2 w-100'>
                            <div className='w-50'>
                                <p className='text-uppercase text-center'>Most played</p>
                                <p className='MapMostPlayed--bigger text-center'>
                                    {mostPlayed.toUpperCase()}
                                </p>
                            </div>
                            <div className='w-75 MapMostPlayed--desc text-center'>
                                {Math.round(props.countMostPlayed)}% of your last played games
                                {/* <Profile profile={ProfileMessagePlayed} type={`btn btn-outline-${typeMessagePlayed} backdrop d-block disabled mt-2 p-2`}>{messagePlayed}</Profile> */}
                            </div>
                        </div>
                    </div>
                </>
            }

        </>

    );
}

export default Map;