import TitreH6 from "../../Titres/TitreH6";
import '../../../assets/css/Alerts.css';

function Alert(props) {
    return (
        <div className={`alert alert-${props.type} px-3 py-2 border-1`}>
            {props.code !== 200 && <TitreH6 className="title-alert px-0">Error {props.code}</TitreH6>}
            <p>{props.children}</p>
        </div>
    );
}

export default Alert;