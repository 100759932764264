import React from 'react';

const TitreH2 = (props) => {
    const classes = `${props.className}`;

    return(
        <>
            <h2 className={classes}>{props.children}</h2>
        </>
    );
}

export default TitreH2;