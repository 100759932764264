import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import ApiGet from '../../../Hooks/ApiGet';
import TitreH5 from '../../Titres/TitreH5';
import Profiles from "../../Profiles/Profiles";
import Profile from "../../Profiles/Profile/Profile";
import moment from 'moment';

// Images maps
import erangel from '../../../assets/images/maps/erangel.jpg';
import karakin from '../../../assets/images/maps/karakin.jpg';
import miramar from '../../../assets/images/maps/miramar.jpg';
import paramo from '../../../assets/images/maps/paramo.jpg';
import sanhok from '../../../assets/images/maps/sanhok.jpg';
import jackal from '../../../assets/images/maps/jackal.jpg';
import vikendi from '../../../assets/images/maps/vikendi.jpg';
import taego from '../../../assets/images/maps/taego.jpg';
import deston from '../../../assets/images/maps/deston.jpg';
import missing from '../../../assets/images/maps/missing.png';

import Causer from '../../../Constants/DamageCauserName';
import DamageCauserIcon from '../../../Constants/DamageCauserIcon';

function Match(props) {
  const causerList = Causer;
  const match = props.matchInfos;
  const [matchTelemetry, setMatchTelemetry] = ApiGet();
  const [map, setMap] = useState();
  const [imgMap, setImgMap] = useState();
  const [mapDate, setMapDate] = useState();
  const [nbAi, setNbAi] = useState(0);
  const [nbPlayers, setNbPlayers] = useState(0);
  const [playerStats, setPlayerStats] = useState();
  const [playerTelemetry, setPlayerTelemetry] = useState();
  const [playerKilledby, setPlayerKilledby] = useState();
  const [matchType, setMatchType] = useState();
  const [StatsNotes, setStatsNotes] = useState([]);
  const [FinalProfiles, setFinalProfiles] = useState();


useEffect(() => {
  if(match) {
    let mapReal = "";
    let lienTelemetry = "";
    const countFromFuncAi = countAIs();
    const countFromFuncPlayerz = countPlayerz();
    let date = match.data.attributes.createdAt;
    let dateFrench = convertDate(date);
    setMapDate(dateFrench);

    mapReal = switchMapNameHandler(match.data.attributes.mapName);
    lienTelemetry = TelemetryLinkHandler();
    setMap(mapReal);
    setMatchTelemetry(`${lienTelemetry}`, true);

    
    if(countFromFuncAi && countFromFuncAi !== undefined) {
      setNbAi(countFromFuncAi);
    }

    if(countFromFuncPlayerz && countFromFuncPlayerz !== undefined) {
      setNbPlayers(countFromFuncPlayerz);
    }

    if(statsFromPlayer() && statsFromPlayer() !== undefined) {
      setPlayerStats(statsFromPlayer());
    }
  }

  replaceGameType();
}, [])

    useEffect(() => {
      if(matchTelemetry && matchTelemetry !== undefined) {
        if(!playerTelemetry) {
           setPlayerTelemetry(checkPlayerTelemetryDatas())
        }
        if(!playerKilledby) {
          setPlayerKilledby(checkPlayerKilledby());
        }

        // if(match.data.id === "301a3ae4-e8c4-4b5d-92f7-ce9e6148f7ef")
        //   console.log('idmatch : ' + match.data.id + ' -> ', matchTelemetry)
      }
        
    }, [matchTelemetry])

    useEffect(() => {
      if(playerStats) {
        
        
        if(playerStats.winPlace === 1 && playerStats.kills > 10) {
          giveStatNote('legend', 'Did a top 1 and killed', playerStats.kills, [11]);
        } else {
          giveStatNote('killsdone', 'Kills', playerStats.kills, [4, 4.5, 5, 6, 7, 8]);
          giveStatNote('drive per game', 'Drive distance', playerStats.rideDistance, [2000, 3000, 4000, 6000, 7000, 9000, 11000]);
          giveStatNote('walk per game', 'Walk distance', playerStats.walkDistance, [2000, 2800,  3000, 3500, 4000, 5000, 6000, 7000, 8000]);
          giveStatNote('swim per game', 'Swim distance', playerStats.swimDistance, [200, 300, 400, 500, 600]);
          giveStatNote('early death', 'Survived %', Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration), [0,3,5,6,7,8,9,10,15, 20, 25, 30, 35, 40, 45, 50, 60]);
          giveStatNote('assistsdone', 'Assists', playerStats.assists, [2, 3, 4, 5, 6]);
          giveStatNote('longestKill', 'Longest Kill', playerStats.longestKill, [200, 300, 350, 400, 450, 500, 550, 600]);
          giveStatNote('boosts', 'Boosts used', playerStats.boosts, [6,7,8,10,11,12,13,15]);
          giveStatNote('roadkills', 'Road kills', playerStats.roadKills, [1,2,3]);
          giveStatNote('vehicleDestroys', 'Destroyed vehicles', playerStats.vehicleDestroys, [1,2,3]);
          giveStatNote('Revives % par game', 'Revives', playerStats.revives, [2,3,4,5]);

          if(playerStats.kills > 0 && playerStats.damageDealt > 0) {
            giveStatNote('dbnos', 'DBNOs / kill', (playerStats.DBNOs / playerStats.kills), [2,3,4]);
            giveStatNote('damagesPerKill', 'Damages / kill', (Math.round(playerStats.damageDealt) / playerStats.kills), [100, 150, 200, 250, 300, 350, 400, 500]);
          } else if (playerStats.kills === 0 && playerStats.damageDealt > 130) {
            giveStatNote('damagesPerKill', 'Damages', (Math.round(playerStats.damageDealt)), [130, 200, 250, 300, 350, 400, 500, 600]);
          } 
          if(playerStats.DBNOs > 0 && playerStats.kills === 0) {
            giveStatNote('dbnos', 'DBNOs', playerStats.DBNOs, [2,3,4]);
          }
          if ((playerStats.damageDealt * 100 / (playerStats.kills *100)) < 70) {
            giveStatNote('killsPardamages', 'Kills % / damages', playerStats.damageDealt * 100 / (playerStats.kills *100), [0, 10, 20, 30, 40, 50, 60])
          }
        }
        
        // S'il n'y a que le profile par defaut 
        if (checkProfilesStats().length === 1) {
          // On défini un status si on a moins de 25% de la game et que la personne a tué des gens
          if((playerStats.kills > 0 && playerStats.kills < 4) && (Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) < 25)) {
            giveStatNote('killsdoneLow', 'Kills', playerStats.kills, [1, 2, 3]);
          } else if((playerStats.kills > 0 && playerStats.kills < 4) && (Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) > 25)) {
            giveStatNote('killsdoneLowHigh', 'You did well in that game', playerStats.kills, [2, 3]);
          }
        }



        setFinalProfiles(checkProfilesStats())
      }
    }, [playerStats])

    // useEffect(() => {
    //   if(FinalProfiles) {
    //     props.addToTopProfile(FinalProfiles[0])
    //   }
    // }, [FinalProfiles])


    function checkPlayerTelemetryDatas() {
      let allTelem = matchTelemetry;
      let playerTelem = [];

      for (let i = 0; i < allTelem.length; i++) {
        if(allTelem[i].character && allTelem[i].character.accountId === props.idPlayer) {
           playerTelem.push(allTelem[i])
        }   
        if((allTelem[i].victim && allTelem[i].victim.accountId === props.idPlayer) && allTelem[i].attacker) {
          playerTelem.push(allTelem[i]);
        }
      }



      return playerTelem;
    }

    function checkPlayerKilledby() {
      let allTelem = matchTelemetry;
      let playerKilledBy = null;
      let damageCauserIcon = DamageCauserIcon.None;

      for (let i = 0; i < allTelem.length; i++) {
        if(allTelem[i].victim && allTelem[i].victim.accountId === props.idPlayer) {

          // On check le tueur
          if(allTelem[i].killer && (allTelem[i].killer.accountId && allTelem[i].killer.name)) {
            let weaponKill = false;
            if(allTelem[i].killerDamageInfo.damageCauserName) {
              for (let ci = 0; ci < Object.values(DamageCauserIcon).length; ci++) {
                if(Object.keys(DamageCauserIcon)[ci] === allTelem[i].killerDamageInfo.damageCauserName) {
                  damageCauserIcon = Object.values(DamageCauserIcon)[ci]   
                }        
              }

              for (let j = 0; j < Object.values(causerList).length; j++) {
                if(Object.keys(causerList)[j] === allTelem[i].killerDamageInfo.damageCauserName) {
                  weaponKill = Object.values(causerList)[j]
                }    
              }
            }            
            
            // Si le tueur est un bot
            if(allTelem[i].killer.accountId.includes('ai')) {
              playerKilledBy = {
                killerId: false,
                killerName: "a Bot",
                weapon : weaponKill,
                icon : damageCauserIcon
              };
            } else {
              playerKilledBy = {
                killerId: allTelem[i].killer.accountId,
                killerName: allTelem[i].killer.name,
                weapon : weaponKill,
                icon : damageCauserIcon
              };
            }
  
          } else if(allTelem[i].killer === null) { // Ou la cause de la mort si pas tué par un joueur
            let causerName = "";

            if(allTelem[i].finishDamageInfo.damageCauserName) {
              for (let ci = 0; ci < Object.values(DamageCauserIcon).length; ci++) {
                if(Object.keys(DamageCauserIcon)[ci] === allTelem[i].finishDamageInfo.damageCauserName) {
                  damageCauserIcon = Object.values(DamageCauserIcon)[ci]   
                }             
              }
            }  

            for (let j = 0; j < Object.values(causerList).length; j++) {
              if(Object.keys(causerList)[j] === allTelem[i].finishDamageInfo.damageCauserName) {
                causerName = Object.values(causerList)[j]
              }    
            }

            if(allTelem[i].teamKillers_AccountId && allTelem[i].dBNOMaker) {
              playerKilledBy = {
                killerId: allTelem[i].teamKillers_AccountId,
                killerName: allTelem[i].dBNOMaker.name,
                weapon : "teamkill",
                icon : DamageCauserIcon.None
              };
            } else {
              playerKilledBy = {
                killerId: false,
                killerName: causerName,
                weapon : false,
                icon : damageCauserIcon
              };
            }

          }
          
        }   
      }

      return playerKilledBy;
    }

    function switchMapNameHandler(mapname) {
        let mapReal = null;
        let imgMapDef = null;
        switch (mapname) {
            case "Baltic_Main":
              mapReal = "Erangel";
              imgMapDef = erangel;
              break;
            case "Chimera_Main":
              mapReal = "Paramo";
              imgMapDef = paramo;
              break;
            case "Desert_Main":
              mapReal = "Miramar";
              imgMapDef = miramar;
              break;
            case "DihorOtok_Main":
              mapReal = "Vikendi";
              imgMapDef = vikendi;
              break;
            case "Erangel_Main":
              mapReal = "Erangel";
              imgMapDef = erangel;
              break;
            case "Heaven_Main":
              mapReal = "Haven";
              imgMapDef = missing;
              break;
            case "Kiki_Main":
              mapReal = "Deston";
              imgMapDef = deston;
              break;
            case "Range_Main":
              mapReal = "Camp Jackal";
              imgMapDef = jackal;
              break;
            case "Savage_Main":
              mapReal = "Sanhok";
              imgMapDef = sanhok;
              break;
            case "Summerland_Main":
              mapReal = "Karakin";
              imgMapDef = karakin;
              break;
            case "Tiger_Main":
              mapReal = "Taego";
              imgMapDef = taego;
              break;
            default:
              break;
          }
          
          setImgMap(imgMapDef)
          return mapReal;
        }

        function countAIs() {
          let countNbAIs = 0;
          
          if(match && match !== undefined) {
            Object.keys(match.included).forEach(matchParticipant => {
              if(match.included[matchParticipant].attributes.stats && match.included[matchParticipant].attributes.stats !== undefined) {
                if(match.included[matchParticipant].attributes.stats.playerId && match.included[matchParticipant].attributes.stats.playerId !== undefined) {
                  if(match.included[matchParticipant].attributes.stats.playerId.includes('ai.')) {
                    countNbAIs = countNbAIs + 1;
                  }
                }
              } 

            });
          }
          return countNbAIs;
        }

        function countPlayerz() {
          let countNbPlayerz = 0;
          
          if(match && match !== undefined) {
            Object.keys(match.included).forEach(matchParticipant => {
              if(match.included[matchParticipant].type && match.included[matchParticipant].type !== undefined) {
                  if(match.included[matchParticipant].type.includes('participant')) {
                    countNbPlayerz = countNbPlayerz + 1;
                  }
              } 

            });
          }
          return countNbPlayerz;
        }

        function convertDate(date) {
          const dateObject = new Date(date);
          const now = new Date();
          const diffMs = now - dateObject;
          const diffMins = Math.floor(diffMs / 60000);
          const diffHrs = Math.floor(diffMs / 3600000);
          let hoursDisplay = "hours";
          let minsDisplay = "minutes";
        
          if (diffHrs === 1) hoursDisplay = "hour";
          if (diffMins === 1) minsDisplay = "minute";
          
          if (diffMins < 60) {
            return `${diffMins} ${minsDisplay} ago`;
          } else if (diffHrs < 5) {
            return `${diffHrs} ${hoursDisplay} ago`;
          } else {
            return moment(date).format('DD/MM/YYYY - HH:mm');
          }
        }
        
        
        

        function statsFromPlayer() {
          let arrayStats = null;
          if(match && match !== undefined) {
            Object.keys(match.included).forEach(matchParticipant => {

              if(match.included[matchParticipant].type && match.included[matchParticipant].type !== undefined) {
                if(match.included[matchParticipant].type.includes('participant')) {

                  if(match.included[matchParticipant].attributes.stats.playerId.includes(props.idPlayer)) {
                    arrayStats = match.included[matchParticipant].attributes.stats;
                  }

                }
              } 
            });
          }
          return arrayStats;
        }

        function TelemetryLinkHandler() {
          let TelemetryLink = "";

          if(match && match !== undefined) {
            for (let inc = 0; inc < match.included.length; inc++) {
              if(match.included[inc].type === "asset") {
                TelemetryLink = match.included[inc].attributes.URL;
              }
            }

            return TelemetryLink;
          }
        }

        function replaceGameType() {
          let realTypeName;

          switch (match.data.attributes.matchType) {
            case 'official':
              realTypeName = "Normal";
              break;
            
            case 'competitive':
              realTypeName = "Ranked";
              
              break;
          
            default:
              realTypeName = "Casual";
              break;
          }

          setMatchType(realTypeName)
        }

        function giveStatNote(stat,title,statVal,levels) {
          let highestNote = 0;
          let arrayInverse = ['killsPardamages', 'early death']
          let statObject = {
            name: stat,
            title: title,
            baseVal: statVal,
            highestNote: highestNote,
            diff : null,
            diffType: null
          };
          const newNotes = StatsNotes;
          let noteExist = false;
          if(statVal >= levels[0]) {
            if(statVal && !isNaN(statVal)) {
              // On boucle dans les valeurs pour définir son niveau
              for (let i = 0; i <= levels.length; i++) {
                  if(statVal >= levels[i]) {
                      highestNote = (i + 1);
                  }
                }  
              
              if(newNotes.length > 0) {
                  // On check sur la stat existe déjà dans le tableau de Notes pour la remplacer
                  for (let i = 0; i < newNotes.length; i++) {
                      if(stat === newNotes[i].name) {
                          newNotes[i].highestNote = highestNote;
                          noteExist = true;
                          if(arrayInverse.includes(stat)) {
                            newNotes[i].diff = (0 + highestNote);
                          } else {
                            newNotes[i].diff = (levels.length - highestNote);
                          }

                          newNotes[i].diffType = "Top";
                          
                      }
                  }
              }
              
              if(noteExist === false) {
                  statObject.highestNote = highestNote;
                  if(arrayInverse.includes(stat)) {
                    statObject.diff = (0 + highestNote);
                  } else {
                    statObject.diff = (levels.length - highestNote);
                  }
                  
                  statObject.diffType = "Top";

  
                  newNotes.push(statObject)
              }
  
              newNotes.sort((a, b) => a.diff - b.diff);
              
              // console.log(match.data.id,'Notes',newNotes)
              setStatsNotes(newNotes);
          }
        
        }
    }

    function checkProfilesStats() {
      if(StatsNotes) {
          const nbProfiles = 4;
          const stats = StatsNotes;
          const profiles = Profiles;
          
          let resultProfiles = stats.slice(0,nbProfiles).map(objStats => {
              let objProfiles = profiles.find(obj => (obj.stat === objStats.name) && (obj.type === objStats.diffType));
              return {...objStats, ...objProfiles};
            });
            
            for (let i = 0; i < resultProfiles.length; i++) {
                resultProfiles[i].desc = resultProfiles[i].desc.replace('PLAYERNAME', props.nom)
            }
            
            return(resultProfiles);
      }
  }

    return (

        <>  
          {(match && match !== undefined && playerStats && playerStats !== undefined) &&
            <div className={`col-12 col-md-5 col-lg-3 overlay no-backdrop ${(playerStats.winPlace === 1) && 'bg-white bg-opacity-10 placetop1'} mb-2 d-flex singleMatch`} datatype-id={match.data.id}>
                <div className='imgContainer position-relative'>
                  <div className='gameType d-flex flex-column gap-1 align-items-start h-100 w-100 justify-content-between pb-1'>
                    <p className="d-flex flex-column gap-1">
                      <span className="d-flex gap-1 flex-wrap flex-column flex-md-row">
                        <span className={`badge rounded-pill btn ${matchType === 'Ranked' && 'bg-body'} ${matchType === 'Normal' && 'bg-primary'} ${(matchType === 'Casual' || match.data.attributes.gameMode === 'lab') && 'bg-warning'} d-flex justify-content-center`}>{matchType} {match.data.attributes.gameMode.replace('-', ' ')}</span>
                        <span className='badge disabled rounded-pill btn btn-danger'>Top <span className='d-inline-block'>{playerStats.winPlace}</span></span>
                      </span>
                      <span className="d-flex gap-1 flex-wrap flex-column flex-md-row">
                        <span className='badge disabled rounded-pill btn btn-light'><span className='d-inline-block'>{Math.round(match.data.attributes.duration / 60)} min</span></span>
                        <span className='badge disabled rounded-pill btn btn-light'>AI : <span className='d-inline-block'>{nbAi && nbAi} / {nbPlayers && nbPlayers}</span></span>
                      </span>
                    </p>
                    {(FinalProfiles && FinalProfiles[0]) && 
                      <>
                        {(FinalProfiles.length === 1 && FinalProfiles[0].name === "Respawn" && (FinalProfiles[0].baseVal > 25 || playerStats.winPlace < 10)) ?
                          <Profile 
                              profile="Useless"
                              statName="You did nothing. Really."
                              statValue="aaa" 
                              type={`bg-primary bg-opacity-50 overlay backdrop w-100 disabled rounded-0 overflow-hidden mt-2 mx-auto mx-md-0 p-1 d-flex flex-column gap-0 rounded-pill`}>
                              {/*FinalProfiles[0].desc*/}
                          </Profile>
                        :
                          <Profile 
                              profile={FinalProfiles[0].name} 
                              statName={FinalProfiles[0].title} 
                              statValue={FinalProfiles[0].stat === "killsdoneLowHigh" ? 'aaa' : FinalProfiles[0].baseVal} 
                              type={`bg-${FinalProfiles[0].alertype} bg-opacity-50 overlay backdrop w-100 disabled rounded-0 overflow-hidden mt-2 mx-auto mx-md-0 p-1 d-flex flex-column gap-0 rounded-pill`}>
                              {/*FinalProfiles[0].desc*/}
                          </Profile>
                        }
                      </>
                    }
                  </div>
                  <img src={imgMap} alt={map && 'Img de '+map} />
                </div>
                
                <div>
                  <small className='dateUperTitle text-muted opacity-75'>{mapDate}</small>
                  <TitreH5 className="mb-1">{map && map}</TitreH5>
                  <p><small>{playerStats.kills} Kills</small> - <small>{playerStats.assists} Assists</small></p>
                  <p><small>{Math.round(playerStats.damageDealt)} Damages</small></p>
                  <p><small>Survived: {Math.round(playerStats.timeSurvived / 60)} min ({Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration)}%)</small></p>
                  {playerStats.revives > 0 &&
                    <p><small>Revives: {playerStats.revives}</small></p>
                  }
                  {playerStats.teamKills > 0 &&
                    <p><small>Team Kills: {playerStats.teamKills}</small></p>
                  }
                  
                  {playerStats.walkDistance > 0 &&
                    <p><small className='d-inline-block'>Walk: {Math.round(playerStats.walkDistance) / 1000}km</small></p>
                  }
                  {playerStats.rideDistance > 0 &&
                    <p><small className='d-inline-block'>Ride: {Math.round(playerStats.rideDistance) / 1000}km</small></p>
                  }
                  {playerStats.swimDistance > 0 &&
                    <p><small className='d-inline-block'>Swim: {Math.round(playerStats.swimDistance) / 1000}km</small></p>
                  }
                  
                  {(playerKilledby && (playerStats.winPlace !== 1 || Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) !== 100)) && 
                    <>
                      <p className="low-lh mt-2">
                        <img className="icon-death d-inline-block" src={playerKilledby.icon} alt="Icon death"/>
                        {playerKilledby.weapon === "teamkill" ?
                            <small className='d-inline-block'>Teamkilled by&nbsp; 
                              <NavLink onClick={() => props.refresh()} to={`/profile/${playerKilledby.killerName}`}>
                                {playerKilledby.killerName}
                              </NavLink>
                            </small>
                        :
                        <>
                          {!playerKilledby.killerId ?
                            <>
                              <small className='d-inline-block'>Died from {playerKilledby.killerName}</small>
                            </>
                          :
                            <>
                              <small className='d-inline-block'>Killed by&nbsp; 
                              <NavLink onClick={() => props.refresh()} to={`/profile/${playerKilledby.killerName}`}>
                                {playerKilledby.killerName}
                              </NavLink></small>
                            </>
                          }
                        </>
                        }
                        
                        
                        {(playerKilledby.weapon && playerKilledby.weapon !== "teamkill")  && <small className='d-inline-block'>&nbsp;with {playerKilledby.weapon}</small>}
                      </p>
                    </>
                  }
                </div>
            </div>
          }
        </>

    );
}

export default Match;