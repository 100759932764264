import { NavLink } from "react-router-dom";
import logoSmall from '../../assets/images/logo192.png';
import TitreH3 from "../Titres/TitreH3";

function Footer(props) {

    return (

        <>
            <footer className='container-fluid'>
                <small>
                    * Note : All profiles and descriptions are purely subjectives and should not be taken seriously. Even though the site is based on real data, it is important to take these informations with humour !
                </small>

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1872482112592191"
     crossOrigin="anonymous"></script>
                <ins className="adsbygoogle"
                    style={{"display":"block"}}
                    data-ad-client="ca-pub-1872482112592191"
                    data-ad-slot="2729398636"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script>

                {/* <div className="footer-gifts fullwidth position-relative">
                    <div className="footer-gifts_texts py-2 px-4">
                        <TitreH3>Support WPAI on Patreon !</TitreH3>
                        <p>By supporting this website you allow it to keep working and provides you this service. Moreover it helps us updating it frequently !</p>
                        <a className="btn btn-dark btn-sm mt-2" href="https://www.patreon.com/WPAI" rel="noreferrer" target={'_blank'} title="Support WPAI on Patreon !">Support us !</a>
                    </div>  
                </div> */}
                <div className='legals'>
                    <div className='container d-flex justify-content-center align-items-center'>
                        <NavLink to={'/'}>
                            <img src={logoSmall} alt="Logo footer WPAI" />
                        </NavLink>
                        <p className='mb-0'>
                            WPAI - Made by&nbsp; 
                            <NavLink onClick={() => props.refresh()} to={'/profile/mikeu'}>
                                miikeu
                            </NavLink>
                            &nbsp;from the idea of&nbsp;
                            <NavLink onClick={() => props.refresh()} to={'/profile/bud0kaii'}>
                                bud0kaii
                            </NavLink>
                            
                        </p>
                        {/* <p>
                            <a href="https://twitter.com/wpaipubg" className="mx-2" target={"_blank"}>
                                <img className="footer-twitter" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/1245px-Twitter-logo.svg.png" /> 
                                Follow WPAI on twitter
                            </a>
                        </p> */}
                    </div>
                    <div>
                        
                    </div>
                </div>
            </footer>
        </>

    );
}

export default Footer;