import loading from '../../assets/images/loading.gif';
import '../../assets/css/Loading.css';

function Loading(props) {
    return (

        <>
            <div className="loading fulled fullwidth">
                <div><img src={loading} alt="Loading"/></div>
                <p>{props.children}</p>
            </div>
        </>

    );
}

export default Loading;