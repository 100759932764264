import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import TitreH1 from "../Components/Titres/TitreH1";

function NotFound() {
    useEffect(() => {
        document.body.className = 'notfound'
        document.title = `404 not found - WPAI`;
    }, [])

    return (

        <div className="container container-notfound pt-4 text-center">
            <div className="row overlay">
                <TitreH1 className="w-100 text-center">
                    404 NOT FOUND
                </TitreH1>
                <p className="text-center">Damn, you're down. Go back to the <NavLink to={'/'}>homepage</NavLink> to get revived !</p>
            </div>
        </div>

    );
}

export default NotFound;