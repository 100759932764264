import { useState } from 'react';
import axios from 'axios';
import Key from '../Constants/Consts';

const PUBG_API_KEY = Key();

function ApiGet() {
  const [datas, setDatas] = useState(null);

  const loadApi = async (url, isApi) => {
    if (isApi) {
      try {
        const reponse = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${PUBG_API_KEY}`,
            Accept: 'application/vnd.api+json',
          },
        });
        const datasLoaded = reponse.data;
        setDatas(datasLoaded);
      } catch (error) {
        if (error.response && error.response.status) {
          console.log('Request error', error.response.status);

          let message = '';
          let type = 'light';

          switch (error.response.status) {
            case 404:
              message = 'Invalid player name';
              type = 'danger';
              break;
            case 429:
              message = 'Too many requests';
              type = 'danger';
              break;
            default:
              break;
          }
          setDatas({
            err: error.response.status,
            msg: message,
            type: type,
          });
        }
      }
    } else {
      setDatas(url);
    }
  };

  return [datas, loadApi];
}

export default ApiGet;


// Ancien code
/* import { useState } from 'react';
import axios from 'axios';
import Key from '../Constants/Consts';

const PUBG_API_KEY = Key();

function ApiGet() {
    const [datas, setDatas] = useState(null);

     const loadApi = (url, isApi) => {
        if(isApi) {
            axios.get(url,{
                headers: {
                  'Authorization': `Bearer ${PUBG_API_KEY}`,
                  'Accept': 'application/vnd.api+json',
                },
              })
            .then(reponse => {
                const datasLoaded = reponse.data;
                
                setDatas(datasLoaded);
            })
            .catch(error => {
                if(error.response && error.response.status) {
                    console.log('Request erreur', error.response.status)

                    let message = "";
                    let type = "light";
    
                    switch (error.response.status) {
                        case 404:
                            message = "Invalid player name";
                            type = "danger";
                            break;
                        case 429:
                            message = "Too many requests"
                            type = "danger";
                            break;                
                        default:
                            break;
                    }
                    setDatas({
                        err: error.response.status,
                        msg: message,
                        type: type
                    })   
                }
            })
        } else {
            setDatas(url);
        }
    }
    return [datas, loadApi];
}

export default ApiGet; */