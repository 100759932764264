import React from 'react';

const TitreH5 = (props) => {
    const classes = `${props.className}`;

    return(
        <>
            <h5 className={classes}>{props.children}</h5>
        </>
    );
}

export default TitreH5;