import React from 'react';

const TitreH1 = (props) => {
    const classes = `${props.className}`;

    return(
        <>
            <h1 className={classes}>{props.children}</h1>
        </>
    );
}

export default TitreH1;