import { useEffect, useState } from 'react';
import TitreH1 from '../Titres/TitreH1';
import Stat from './Stats/Stat';
import StatsModal from './StatsModal';

function AllTimeStats(props) {
    const [WinRate, setWinRate] = useState(0);
    const [TopRate, setTopRate] = useState(0);

    useEffect(() => {
        setWinRate(props.WinRate)
        setTopRate(props.Top10s)

    }, []);

    useEffect(() => {
        if(WinRate && TopRate && props) {
            animateStatValues();
        }
    }, [props, setTopRate, setWinRate])

    function animateStatValues() {
        const elements = document.querySelectorAll('.stat-value span');

            // elements.forEach(element => {
            //     const currentNumber = element.dataset.val;
            //     let start = 0;
            //     const duration = 2400;
            //     const step = (timestamp) => {
            //         if (!start) start = timestamp;
            //         const progress = timestamp - start;
            //         const nextNumber = currentNumber * (progress / duration);

            //         element.textContent = nextNumber.toFixed(2);
            //         if (progress < duration) {
            //             window.requestAnimationFrame(step);
            //         }
            //     };

            //     window.requestAnimationFrame(step);
                
            //     setTimeout(() => {
            //         element.textContent = element.dataset.val;
            //     }, 2500);
            // });
    }

    function showAllStatsHandler() {
        var modal = document.getElementById("StatsModal");
            modal.classList.toggle("active")
      }

    return (

        <>

            <div className='fullwidth mt-5 overlay py-2 py-md-4 px-3 px-md-5'>
                <TitreH1 className="text-uppercase text-center w-75 mx-auto mb-4">
                    All Time "Normal" Stats for {props.hoursPlayed} hours played
                </TitreH1>
                
                <div className='d-flex justify-content-between flex-wrap gap-2'>
                    <Stat Additionnal='(The Higher the best : avg. 1.3)' statValue={Math.round(props.reputationScore * 100) / 100}>Score WPAI : </Stat>
                    <Stat statValue={Math.round(props.kda * 100) / 100}>KDA :</Stat>
                    <Stat statPercent={true} statValue={Math.round(WinRate * 100) / 100}>Win rate :</Stat>
                    <Stat statPercent={true} statValue={Math.round(TopRate * 100) / 100}>Top 10 rate :</Stat>      
                    <Stat statValue={props.totalGames}>Total Games :</Stat>  
                    <Stat statValue={props.suicides}>Suicides :</Stat>
                    <Stat statValue={props.tks}>TeamKills :</Stat>
                    <Stat statMeter={true} statValue={Math.round(props.longestKill* 100)/ 100}>Longest Kill :</Stat>        
                </div>

                <div className='text-center'>
                    <span className='btn btn-link p-0' onClick={showAllStatsHandler}>See more stats</span>
                </div>
            </div>
            <StatsModal {...props} click={() => showAllStatsHandler()}>
                    <Stat statValue={props.totalGames}>Total Games</Stat>  
                    <Stat statValue={Math.round(props.DaysPlayed * 100) / 100}>Days Played</Stat>
                    <Stat statValue={Math.round(props.hoursPlayed * 100) / 100}>Hours Played</Stat>

                    <Stat statValue={Math.round(props.kda * 100) / 100}>KDA</Stat>
                    <Stat statPercent={true} statValue={Math.round(WinRate * 100) / 100}>Win rate</Stat>
                    <Stat statPercent={true} statValue={Math.round(TopRate * 100) / 100}>Top 10 rate</Stat> 

                    <Stat statValue={Math.round(props.reputationScore * 100) / 100}>Score WPAI</Stat>
                    <Stat statValue={props.suicides}>Suicides</Stat>
                    <Stat statPercent={true} statValue={Math.round(((props.suicides / props.totalGames) * 100) * 100) / 100}>Suicide % / game</Stat>
                    
                    <Stat statValue={props.tks}>TeamKills</Stat>
                    <Stat statPercent={true} statValue={Math.round(((props.tks / props.totalGames) * 100) * 100) / 100}>Teamkill % / game</Stat>
                    <Stat statMeter={true} statValue={Math.round(props.longestKill* 100)/ 100}>Longest Kill</Stat>   
                    
                    <Stat statValue={Math.round(props.Revives* 100)/ 100}>Revives</Stat>   
                    <Stat statPercent={true} statValue={Math.round(((props.Revives / props.totalGames) * 100) * 100) / 100}>Revives % per/ game</Stat>
                    <Stat statValue={Math.round(props.DamagesPerKill* 100)/ 100}>Damages / kill</Stat>   

                    <Stat statValue={Math.round(props.WalkPerGame* 100)/ 100}>Walk / Game</Stat> 
                    <Stat statMeter={true} statValue={Math.round(props.RidePerGame* 100)/ 100}>Ride / Game</Stat>   
                    <Stat statMeter={true} statValue={Math.round(props.SwimPerGame* 100)/ 100}>Swim / game</Stat> 
            </StatsModal>
        </>

    );
}

export default AllTimeStats;