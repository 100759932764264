import React from 'react';

const TitreH4 = (props) => {
    const classes = `${props.className}`;

    return(
        <>
            <h4 className={classes}>{props.children}</h4>
        </>
    );
}

export default TitreH4;