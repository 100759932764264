import React from 'react';
import TitreH2 from '../../Titres/TitreH2';
import './Profile.css';

const Profile = (props) => {
    const classes = `profile-block ${props.type}`;

    return(
        <>
            <div className={classes}>
                <TitreH2 className="mb-0">{props.profile}</TitreH2>
                {props.children && <p>{props.children}</p>}
                {(props.statValue && props.statName) 
                && 
                <>
                    {(props.statValue === "aaa") ?
                        <small>{props.statName}</small>
                    :
                        <small>{props.statName} : {Math.round(props.statValue * 100) / 100}</small>
                    }
                </>
                }
            </div>
        </>
    );
}

export default Profile;