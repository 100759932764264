import React from 'react';

const Bouton = (props) => {
    const btnClasses = `btn m-2 ${props.className}`;
    return(
        <>
            <button className={btnClasses} onClick={props.click}>{props.children}</button>
        </>
    );
}

export default Bouton;