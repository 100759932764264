// Images deaths
import Blackzone from '../assets/images/Killfeed/Blackzone.png';
import Bluezone from '../assets/images/Killfeed/Bluezone.png';
import Death from '../assets/images/Killfeed/Death.png';
import Drowning from '../assets/images/Killfeed/Drown.png';
import Ferry from '../assets/images/Killfeed/Ferry.png';
import Loot_Truck from '../assets/images/Killfeed/Loot_Truck.png';
import Melee_Throw from '../assets/images/Killfeed/Melee_Throw.png';
import Redzone from '../assets/images/Killfeed/Redzone.png';
import Vehicle from '../assets/images/Killfeed/Vehicle.png';



const DamageCauserIcon = {
    "AIPawn_Base_Female_C": Death,
    "AIPawn_Base_Male_C": Death,
    "AirBoat_V2_C": Vehicle,
    "AquaRail_A_01_C": Vehicle,
    "AquaRail_A_02_C": Vehicle,
    "AquaRail_A_03_C": Vehicle,
    "BP_ATV_C": Vehicle,
    "BP_BRDM_C": Vehicle,
    "BP_Bicycle_C": Vehicle,
    "BP_CoupeRB_C": Vehicle,
    "BP_DO_Circle_Train_Merged_C": Vehicle,
    "BP_DO_Line_Train_Dino_Merged_C": Vehicle,
    "BP_DO_Line_Train_Merged_C": Vehicle,
    "BP_Dirtbike_C": Vehicle,
    "BP_DronePackage_Projectile_C": Death,
    "BP_Eragel_CargoShip01_C": Ferry,
    "BP_FakeLootProj_AmmoBox_C": Loot_Truck,
    "BP_FakeLootProj_MilitaryCrate_C": Loot_Truck,
    "BP_FireEffectController_C": Melee_Throw,
    "BP_FireEffectController_JerryCan_C": Death,
    "BP_Helicopter_C": Vehicle,
    "BP_IncendiaryDebuff_C": Death,
    "BP_JerryCanFireDebuff_C": Death,
    "BP_JerryCan_FuelPuddle_C": Death,
    "BP_KillTruck_C": Vehicle,
    "BP_LootTruck_C": Loot_Truck,
    "BP_M_Rony_A_01_C": Vehicle,
    "BP_M_Rony_A_02_C": Vehicle,
    "BP_M_Rony_A_03_C": Vehicle,
    "BP_Mirado_A_02_C": Vehicle,
    "BP_Mirado_A_03_Esports_C": Vehicle,
    "BP_Mirado_Open_03_C": Vehicle,
    "BP_Mirado_Open_04_C": Vehicle,
    "BP_Mirado_Open_05_C": Vehicle,
    "BP_MolotovFireDebuff_C": Melee_Throw,
    "BP_Motorbike_04_C": Vehicle,
    "BP_Motorbike_04_Desert_C": Vehicle,
    "BP_Motorbike_04_SideCar_C": Vehicle,
    "BP_Motorbike_04_SideCar_Desert_C": Vehicle,
    "BP_Motorbike_Solitario_C": Vehicle,
    "BP_Motorglider_C": Vehicle,
    "BP_Motorglider_Green_C": Vehicle,
    "BP_Niva_01_C": Vehicle,
    "BP_Niva_02_C": Vehicle,
    "BP_Niva_03_C": Vehicle,
    "BP_Niva_04_C": Vehicle,
    "BP_Niva_05_C": Vehicle,
    "BP_Niva_06_C": Vehicle,
    "BP_Niva_07_C": Vehicle,
    "BP_PickupTruck_A_01_C": Vehicle,
    "BP_PickupTruck_A_02_C": Vehicle,
    "BP_PickupTruck_A_03_C": Vehicle,
    "BP_PickupTruck_A_04_C": Vehicle,
    "BP_PickupTruck_A_05_C": Vehicle,
    "BP_PickupTruck_A_esports_C": Vehicle,
    "BP_PickupTruck_B_01_C": Vehicle,
    "BP_PickupTruck_B_02_C": Vehicle,
    "BP_PickupTruck_B_03_C": Vehicle,
    "BP_PickupTruck_B_04_C": Vehicle,
    "BP_PickupTruck_B_05_C": Vehicle,
    "BP_Pillar_Car_C": Vehicle,
    "BP_PonyCoupe_C": Vehicle,
    "BP_Porter_C": Vehicle,
    "BP_Scooter_01_A_C": Vehicle,
    "BP_Scooter_02_A_C": Vehicle,
    "BP_Scooter_03_A_C": Vehicle,
    "BP_Scooter_04_A_C": Vehicle,
    "BP_Snowbike_01_C": Vehicle,
    "BP_Snowbike_02_C": Vehicle,
    "BP_Snowmobile_01_C": Vehicle,
    "BP_Snowmobile_02_C": Vehicle,
    "BP_Snowmobile_03_C": Vehicle,
    "BP_Spiketrap_C": Death,
    "BP_TslGasPump_C": Death,
    "BP_TukTukTuk_A_01_C": Death,
    "BP_TukTukTuk_A_02_C": Death,
    "BP_TukTukTuk_A_03_C": Death,
    "BP_Van_A_01_C": Death,
    "BP_Van_A_02_C": Death,
    "BP_Van_A_03_C": Death,
    "BattleRoyaleModeController_Chimera_C": Bluezone,
    "BattleRoyaleModeController_Def_C": Bluezone,
    "BattleRoyaleModeController_Desert_C": Bluezone,
    "BattleRoyaleModeController_DihorOtok_C": Bluezone,
    "BattleRoyaleModeController_Heaven_C": Bluezone,
    "BattleRoyaleModeController_Kiki_C": Bluezone,
    "BattleRoyaleModeController_Savage_C": Bluezone,
    "BattleRoyaleModeController_Summerland_C": Bluezone,
    "BattleRoyaleModeController_Tiger_C": Bluezone,
    "BlackZoneController_Def_C": Blackzone,
    "Bluezonebomb_EffectActor_C": Melee_Throw,
    "Boat_PG117_C": Vehicle,
    "Buff_DecreaseBreathInApnea_C": Drowning,
    "Buggy_A_01_C": Vehicle,
    "Buggy_A_02_C": Vehicle,
    "Buggy_A_03_C": Vehicle,
    "Buggy_A_04_C": Vehicle,
    "Buggy_A_05_C": Vehicle,
    "Buggy_A_06_C": Vehicle,
    "Carepackage_Container_C": Death,
    "Dacia_A_01_v2_C": Vehicle,
    "Dacia_A_01_v2_snow_C": Vehicle,
    "Dacia_A_02_v2_C": Vehicle,
    "Dacia_A_03_v2_C": Vehicle,
    "Dacia_A_03_v2_Esports_C": Vehicle,
    "Dacia_A_04_v2_C": Vehicle,
    "DroppedItemGroup": Death,
    "EmergencyAircraft_Tiger_C": Death,
    "Jerrycan": Death,
    "JerrycanFire": Death,
    "Lava": Death,
    "Mortar_Projectile_C": Death,
    "None": Death,
    "PG117_A_01_C": Death,
    "PanzerFaust100M_Projectile_C": Death,
    "PlayerFemale_A_C": Death,
    "PlayerMale_A_C": Death,
    "ProjC4_C": Death,
    "ProjGrenade_C": Melee_Throw,
    "ProjIncendiary_C": Melee_Throw,
    "ProjMolotov_C": Melee_Throw,
    "ProjMolotov_DamageField_Direct_C": Melee_Throw,
    "ProjStickyGrenade_C": Melee_Throw,
    "RacingDestructiblePropaneTankActor_C": Death,
    "RacingModeContorller_Desert_C": Bluezone,
    "RedZoneBomb_C": Redzone,
    "RedZoneBombingField": Redzone,
    "RedZoneBombingField_Def_C": Redzone,
    "TslDestructibleSurfaceManager": Death,
    "TslPainCausingVolume": Death,
    "Uaz_A_01_C": Vehicle,
    "Uaz_Armored_C": Vehicle,
    "Uaz_B_01_C": Vehicle,
    "Uaz_B_01_esports_C": Vehicle,
    "Uaz_C_01_C": Vehicle,
    "UltAIPawn_Base_Female_C": Death,
    "UltAIPawn_Base_Male_C": Death,
    "WeapACE32_C": Death,
    "WeapAK47_C": Death,
    "WeapAUG_C": Death,
    "WeapAWM_C": Death,
    "WeapBerreta686_C": Death,
    "WeapBerylM762_C": Death,
    "WeapBizonPP19_C": Death,
    "WeapCowbarProjectile_C": Melee_Throw,
    "WeapCowbar_C": Melee_Throw,
    "WeapCrossbow_1_C": Melee_Throw,
    "WeapDP12_C": Death,
    "WeapDP28_C": Death,
    "WeapDesertEagle_C": Death,
    "WeapDuncansHK416_C": Death,
    "WeapFNFal_C": Death,
    "WeapG18_C": Death,
    "WeapG36C_C": Death,
    "WeapGroza_C": Death,
    "WeapHK416_C": Death,
    "WeapJuliesKar98k_C": Death,
    "WeapK2_C": Death,
    "WeapKar98k_C": Death,
    "WeapL6_C": Death,
    "WeapLunchmeatsAK47_C": Death,
    "WeapM16A4_C": Death,
    "WeapM1911_C": Death,
    "WeapM249_C": Death,
    "WeapM24_C": Death,
    "WeapM9_C": Death,
    "WeapMG3_C": Death,
    "WeapMP5K_C": Death,
    "WeapMP9_C": Death,
    "WeapMacheteProjectile_C": Melee_Throw,
    "WeapMachete_C": Death,
    "WeapMadsQBU88_C": Death,
    "WeapMini14_C": Death,
    "WeapMk12_C": Death,
    "WeapMk14_C": Death,
    "WeapMk47Mutant_C": Death,
    "WeapMosinNagant_C": Death,
    "WeapNagantM1895_C": Death,
    "WeapOriginS12_C": Death,
    "WeapP90_C": Death,
    "WeapPanProjectile_C": Melee_Throw,
    "WeapPan_C": Melee_Throw,
    "WeapPanzerFaust100M1_C": Death,
    "WeapQBU88_C": Death,
    "WeapQBZ95_C": Death,
    "WeapRhino_C": Death,
    "WeapSCAR-L_C": Death,
    "WeapSKS_C": Death,
    "WeapSaiga12_C": Death,
    "WeapSawnoff_C": Death,
    "WeapSickleProjectile_C": Melee_Throw,
    "WeapSickle_C": Melee_Throw,
    "WeapThompson_C": Death,
    "WeapTurret_KillTruck_Main_C": Death,
    "WeapUMP_C": Death,
    "WeapUZI_C": Death,
    "WeapVSS_C": Death,
    "WeapVector_C": Death,
    "WeapWin94_C": Death,
    "WeapWinchester_C": Death,
    "Weapvz61Skorpion_C": Death
  }

  export default DamageCauserIcon;