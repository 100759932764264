import { useEffect, useState } from 'react';
import axios from 'axios';
import TitreH6 from '../Components/Titres/TitreH6';
import TitreH1 from '../Components/Titres/TitreH1';

function AdminTwitch(props) {
    const [chaines, setChaines] = useState();
    const [admins, setAdmins] = useState();
    const [inputName, setInputName] = useState();
    const [inputPw, setInputPw] = useState();
    const [refresh, setRefresh] = useState(false);
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {       
        getTwitchDatas();
        getAdminsDatas();
    }, []);
        
    const TwitchValidationHandler = (id) => {
        let newChaines = chaines;
        for (let i = 0; i < newChaines.length; i++) {
            if(newChaines[i].id === id) {
                newChaines[i].validate = !newChaines[i].validate;

                setRefresh(true);
                newChaines.sort((a, b) => b.id - a.id);
                props.toAlertHandler(1337,`Chaine ${newChaines[i].nompubg} ${newChaines[i].validate ? 'validée' : 'non-validée'}`, 'success')
                setChaines(newChaines);
                updateTwitchDatas();

                
                setTimeout(() => {
                    setRefresh(false)
                }, 1);
            } 
        }
    }
    
    const getTwitchDatas = () => {
        axios.get('https://wpai-twitch-default-rtdb.europe-west1.firebasedatabase.app/Chaines.json')
        .then(reponse => {
            const Array = Object.values(reponse.data)
            setChaines(Array);
        })
        .catch(error => {
            console.log(error)
     })
    }

    const updateTwitchDatas = () => {
        axios.put(`https://wpai-twitch-default-rtdb.europe-west1.firebasedatabase.app/Chaines.json`, chaines)
            .then(response => {
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getAdminsDatas = () => {
        axios.get('https://wpai-twitch-default-rtdb.europe-west1.firebasedatabase.app/admins.json')
        .then(reponse => {
            const Array = Object.values(reponse.data)
            setAdmins(Array);
        })
        .catch(error => {
            console.log(error)
     })
    }

    const checkIsAdmin = (e) => {
        e.preventDefault();
        
        let isAdmin = false;
        for (let i = 0; i < admins.length; i++) {
            if((admins[i].nom === inputName) && (admins[i].mdp === inputPw)) {
                isAdmin = true;
            }
        }

        if(isAdmin) {
            setIsLogged(true);
        } else {
            props.toAlertHandler(666, 'Wrong Username or Password', 'danger')
        }
    }

    return (
        <>
            {!isLogged ?
                <>
                    <form className='container container-perso-home'>
                        <div className='row'>
                            <TitreH1 className="w-100 pt-4">Administration login</TitreH1>
                        </div>
                        <div className='form-group row'>
                            <input type="text" className="form-control px-2 mb-2" placeholder="Enter name" onChange={(e) => setInputName(e.target.value)}/>
                            <input type="password" className="form-control px-2 mb-2" placeholder="Enter password" onChange={(e) => setInputPw(e.target.value)}/>
                        </div>
                        <button className='btn btn-primary mx-0' onClick={(e) => checkIsAdmin(e)}>Log me in</button>
                    </form>
                </>
            :
                <>
                    {(chaines && chaines != null && !refresh) && 
                    <>
                        <div className='container'>
                            <div className='row'>
                                <TitreH1 className="col-12 mt-2">Administration des chaines Twitch</TitreH1>
                                {
                                    chaines.map(chaine => {
                                        return (
                                            <>
                                                {chaine && chaine !== null
                                                &&
                                                    <>
                                                        <div key={chaine.id} className='col-6 col-md-4 p-2'>
                                                            <div className={`w-100 ${chaine.validate ? 'overlay' : 'overlay-light'}`}>
                                                                <TitreH6 className='w-100 mb-1 d-flex justify-content-sm-between'>{chaine.nompubg} <span className={`badge rounded-pill mx-1 ${chaine.validate ? 'bg-success' : 'bg-danger' }`}>{chaine.validate ? '✓' : '✘'}</span></TitreH6>
                                                                <small>Twitch : {chaine.chaine}</small>
                                                                <form>
                                                                    <fieldset>
                                                                        <fieldset className="form-group w-25">
                                                                            <div className="form-check form-switch">
                                                                                <input 
                                                                                    checked={chaine.validate ? true : false} 
                                                                                    className="form-check-input p-0" 
                                                                                    type="checkbox" 
                                                                                    id={`flexSwitchCheckDefault${chaine.id}`}  
                                                                                    onChange={() => TwitchValidationHandler(chaine.id)}   
                                                                                />
                                                                            </div>
                                                                        </fieldset>
                                                                    </fieldset>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </>
                    }
                </>
            }
        </>
    );
}

export default AdminTwitch;