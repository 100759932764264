
function FAQitem(props) {
    return (
        <div className={`faq-item ${props.active && 'active'}`} onClick={props.click}>
            <b>{props.question}</b>
            <p>{props.children}</p>
        </div>
    );
}

export default FAQitem;