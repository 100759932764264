import { useEffect, useState } from 'react';
import Match from './Match';
import TitreH1 from '../../Titres/TitreH1';
import '../../../assets/css/SingleMatch.css';
import axios from 'axios';
import Key from '../../../Constants/Consts';
import Loading from '../../Loading/Loading';

function Matches(props) {
    const matches = props.matchesId;
    const [fetchApi, setfetchApi] = useState();
    const PUBG_API_KEY = Key();
    const matchesNbToCount = 36;
    const [matchesNbToDisplay, setMatchesNbToDisplay] = useState(12);
    const [matchesProfile, setMatchesProfile] = useState([]);
    const [TopProfile, setTopProfile] = useState();

    useEffect(() => {
        if(matches) {       
 
          axios.post('https://wpai-cors.patatedouce-studio.fr/api/matches/', 
            {
              data : matches,
              key : PUBG_API_KEY,
              nbmatches : matchesNbToCount,
              excluded : 'tdm'
            },
            {
              mode: 'no-cors'
            }
          )
        
          .then(reponse => {
            if(reponse.data) {
              // console.log(reponse.data)
              setfetchApi(reponse.data)
            }
          })
          .catch(error => {
            console.log(error)
          })

        }
    }, [])

    useEffect(() => {
      if(fetchApi && fetchApi !== undefined) {
        props.getCountMostPlayedHandler(checkMostPlayedMap()[1], matchesNbToCount);
        props.getMostPlayedHandler(checkMostPlayedMap()[0]);
      }
    }, [fetchApi])

    // useEffect(() => {
    //   if(matchesProfile && matchesProfile.length === matchesNbToDisplay) {
    //     checkTopProfile();
    //   }
    // }, [matchesProfile.length])

    // function addToTopProfile(profile) {
    //   let arrayTop = matchesProfile;
    //   arrayTop.push(profile);
    //   setMatchesProfile(arrayTop);
    // }

    // function checkTopProfile() {
    //   let topProfiles = [];
    //   let profileIsIn = false;

    //   for (let p = 0; p < matchesProfile.length; p++) {
    //     if(topProfiles.length === 0) {
    //       topProfiles.push(matchesProfile[p]);
    //     } else {
    //       for (let i = 0; i < topProfiles.length; i++) {
    //         if(!topProfiles[i].count) {
    //           topProfiles[i].count = 1;
    //         }
    //         if(topProfiles[i].name === matchesProfile[p].name) {
    //           profileIsIn = true;
              
    //           topProfiles[i].count = topProfiles[i].count + 1;
    //         }
    //       }
    //     }

    //     if(!profileIsIn) {
    //       topProfiles.push(matchesProfile[p]);
    //     }
    //     profileIsIn = false;
    //   }

    //   topProfiles.sort((b,a) => a.count - b.count);
    //   setTopProfile(topProfiles[0]);
    // }



    function checkMostPlayedMap() {
      let mapsCount = {
          Vikendi : 0,
          Erangel : 0,
          Sanhok : 0,
          Miramar : 0,
          Deston : 0,
          Paramo : 0,
          Haven : 0,
          Karakin : 0,
          Taego : 0,
          Jackal : 0
      }


      if(fetchApi && fetchApi !== undefined) {
          for (let i = 0; i < fetchApi.length; i++) {
              switch (fetchApi[i].data.attributes.mapName) {
                  case "Baltic_Main":
                      mapsCount.Erangel = mapsCount.Erangel + 1;
                    break;
                  case "Chimera_Main":
                      mapsCount.Paramo = mapsCount.Paramo + 1;
                    break;
                  case "Desert_Main":
                      mapsCount.Miramar = mapsCount.Miramar + 1;
                    break;
                  case "DihorOtok_Main":
                      mapsCount.Vikendi = mapsCount.Vikendi + 1;
                    break;
                  case "Erangel_Main":
                      mapsCount.Erangel = mapsCount.Erangel + 1;
                    break;
                  case "Heaven_Main":
                      mapsCount.Haven = mapsCount.Haven + 1;
                    break;
                  case "Kiki_Main":
                      mapsCount.Deston = mapsCount.Deston + 1;
                    break;
                  case "Range_Main":
                      mapsCount.Jackal = mapsCount.Jackal + 1;
                    break;
                  case "Savage_Main":
                      mapsCount.Sanhok = mapsCount.Sanhok + 1;
                    break;
                  case "Summerland_Main":
                      mapsCount.Karakin = mapsCount.Karakin + 1;
                    break;
                  case "Tiger_Main":
                    mapsCount.Taego = mapsCount.Taego + 1;
                    break;
                  default:
                    break;
                }
          }

          
          let maxValue = Math.max(...Object.values(mapsCount));
          let mostPlayedMap = Object.keys(mapsCount).find(key => mapsCount[key] === maxValue);

          return(
            [mostPlayedMap, maxValue]
            );
      }
  }



    
    return (

        <>  
            {(fetchApi && fetchApi !== undefined) ?
              <>
              <div className='fulled d-flex flex-column flex-sm-row justify-content-sm-between justify-content-start align-items-baseline align-items-sm-end'>
                <div>
                  <TitreH1 className="title-matches mb-0 text-uppercase">
                  {matchesNbToDisplay < matchesNbToCount ?
                    <span>{matchesNbToDisplay} </span>
                    :
                    <span>{matchesNbToCount} </span>
                  }
                  Last games
                  </TitreH1>
                  
                  {props.refreshTimeDiff > 0 && 
                    <small className='text-white-50'>{`${props.refreshTimeDiff} minutes before new matches datas`}</small>
                  }
                  {props.refreshTimeDiff === 0 &&
                    <>
                      <small>You can now <button className="btn btn-link p-0 btn-link-refresh" onClick={props.refreshPageHandler}><small>refresh</small></button> your page</small>
                    </>
                  } 
    
                </div>
                <p className='d-flex align-items-baseline gap-1'>
                  
                  {matchesNbToDisplay < matchesNbToCount &&
                    <button className='btn btn-link p-0' onClick={() => setMatchesNbToDisplay(matchesNbToDisplay+12)}><small>[see more]</small></button>
                  }
                </p>
                
                
              </div>

              {
                fetchApi.slice(0,matchesNbToDisplay).map(match => {
                      return (
                          <Match nom={props.nom} matchInfos={match} refresh={props.refresh} key={match.data.id} idMatch={match.data.id} idPlayer={props.playerId} />
                      );
                  })
              }
              </>
              :
              <>
                {(matchesNbToCount && matchesNbToCount > 0) && 
                  <Loading>Loading Games</Loading>
                }
              </>
            }
        </>

    );
}

export default Matches;