import TitreH4 from '../Titres/TitreH4';
import helmet from '../../assets/images/playerunknowns-battlegrounds-pubg-helmet-18.png';
import cross from '../../assets/images/delete.png';

function ModalSearchPlayers(props) {
    return (

        <>
            <div className='overlay-light listeJoueurs'>
                <TitreH4 className="w-100">Your last profiles</TitreH4>
                {
                    props.listeJoueurs.map(joueur => {
                        return (
                            <p className='position-relative mb-2 d-flex justify-content-between align-items-center' key={joueur}>
                                <span className='playerName' onClick={() => props.sendPlayerNameHandler(joueur)}>
                                    <img src={helmet} alt="Helmet Icon" />
                                    <span className='mx-1'>{joueur}</span>
                                </span>
                                <img className='deleteIcon' src={cross} alt="Delete icon" onClick={() => props.removeFromLocalStorage(joueur)} />
                            </p>
                        );
                    })
                }
            </div>
        </>

    );
}

export default ModalSearchPlayers;