import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.css';
import PlayerProfile from './Pages/PlayerProfile';
import Accueil from './Pages/Accueil';
import Alerts from './Components/Alerts/Alerts';
import { useState } from 'react';
import Footer from './Components/Footer/Footer';
import AdminTwitch from './Pages/AdminTwitch';
import AskTwitch from './Pages/AskTwitch';
import NotFound from './Pages/404';

function App() {
  const [isAlert, setIsAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertCode, setAlertCode] = useState(0);
  const [alertType, setAlertType] = useState("");
  const [refreshedPage, setRefreshedPage] = useState(false);

  function toAlertHandler(nb,msg,type) {
    setIsAlert(true)
    setAlertMsg(msg)
    setAlertType(type)
    setAlertCode(nb)

    setTimeout(() => {
      setIsAlert(false)
    }, 3000) // 3s

    setTimeout(() => {
      setAlertMsg("")
      setAlertType("")
      setAlertCode("")
    }, 3500) // 3.5s
  }


  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 50)
    setTimeout(() => {
      refreshPageHandler(false)
    }, 150)
    
  };

  const refreshPageHandler = (bool) => {
    if(bool) {
      setRefreshedPage(bool)
    } else {
      if(refreshedPage) {
        setRefreshedPage(false);
      } else {
        setRefreshedPage(true);
      }
    }
  };

  return (
    <>
      <Alerts isAlert={isAlert} code={alertCode} msg={alertMsg} type={alertType} />

      <BrowserRouter basename='/'>
        <Switch>
          <Route path="/" exact render={() => <Accueil refresh={refreshPage} />} />
          <Route path="/profile/:nom" render={(props) => <PlayerProfile refreshPageHandler={refreshPageHandler} refreshedPage={refreshedPage} refresh={refreshPage} toAlertHandler={toAlertHandler} {...props} />} />
          <Route path="/contact" exact render={() => <h1>Home</h1>} />
          <Route path="/AdminTwitch" exact render={() => <AdminTwitch toAlertHandler={toAlertHandler} />}></Route>
          <Route path="/addmytwitch" exact render={() => <AskTwitch toAlertHandler={toAlertHandler} />}></Route>
          <Route render={() => <NotFound />} />
        </Switch>

      <Footer refresh={refreshPage} />
      </BrowserRouter>

    </>
  );
}

export default App;
