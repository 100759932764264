import { useEffect, useState } from 'react';
import axios from 'axios';

function Twitch(props) {
    const [allChaines, setAllChaines] = useState();
    const [chaine, setChaine] = useState();

    useEffect(() => {
        getTwitchDatas();
    }, [])

    useEffect(() => {
        hasChaine(props.nom);
    }, [allChaines])

    const getTwitchDatas = () => {
        axios.get('https://wpai-twitch-default-rtdb.europe-west1.firebasedatabase.app/Chaines.json')
        .then(reponse => {
            const Array = Object.values(reponse.data)
            setAllChaines(Array);
        })
        .catch(error => {
            console.log(error)
     })
    }

    function hasChaine(nom) {
        if(allChaines) {
            let playerTwitch = false;
        
            for (let i = 0; i < allChaines.length; i++) {
                if(allChaines[i] && (allChaines[i].nompubg === props.nom) && allChaines[i].validate) {
                    playerTwitch = allChaines[i];
                }
            }
            
            setChaine(playerTwitch);
        }
    }

    return (

        <>
            {chaine && 
                <>
                    <iframe src={`https://player.twitch.tv/?channel=${chaine.chaine}&parent=${window.location.hostname}`} frameBorder="0" allowFullScreen={true} scrolling="no" height="378" width="100%" className='fullwidth'></iframe>
                </> 
            }
        </>

    );
}

export default Twitch;