import { useState, useEffect } from 'react';
import ApiGet from '../../../Hooks/ApiGet';
import Profile from '../../Profiles/Profile/Profile';
import Profiles from '../../Profiles/Profiles';
import TitreH5 from '../../Titres/TitreH5';

function SeasonsProfiles(props) {
    const [SeasonToSet, setSeasonToSet] = useState(false)
    const [currentSeasonIsSet, setCurrentSeasonIsSet] = useState(false)
    const [lastSeasonIsSet, setLastSeasonIsSet] = useState(false)
    const [SeasonStats, setSeasonStats] = ApiGet();
    const [SeasonFromApiStats, setSeasonFromApiStats] = ApiGet();
    const [StatsNotes, setStatsNotes] = useState([]);
    const [FinalProfiles, setFinalProfiles] = useState();
    const [Kda, setKda] = useState();
    const [Tks, setTks] = useState(0);
    const [DaysPlayed, setDaysPlayed] = useState(0);
    const [HoursPlayed, setHoursPlayed] = useState(0);
    const [Suicides, setSuicides] = useState(0);
    const [Revives, setRevives] = useState(0);
    const [SwimPerGame, setSwimPerGame] = useState(0);
    const [WalkPerGame, setWalkPerGame] = useState(0);
    const [RidePerGame, setRidePerGame] = useState(0);
    const [WinRate, setWinRate] = useState(0);
    const [Top10s, setTop10s] = useState(0);
    const [DamagesPerKill, setDamagesPerKill] = useState(0);
    const [TotalGames, setTotalGames] = useState(0);
    const [LongestKill, setLongestKill] = useState(0);
    const [reputationScore, setReputationScore] = useState(0);
    const profilesPUBG = JSON.parse(localStorage.getItem("Profiles-ureg-pubg")) || [];

    useEffect(() => {
        if(!SeasonStats){
            if(profilesPUBG) {
                checkSeasonProfile()
            }
        }

    }, [])

    useEffect(() => {
        if(SeasonToSet) {
            setSeasonFromApiStats(`https://api.pubg.com/shards/steam/players/${props.playerId}/seasons/${props.currentSaison}`, true)
            console.log('add season from api');
        } else {
            addSeasonToState()
        }
    }, [SeasonToSet])

    useEffect(() => {
        modifyProfile()
    }, [SeasonFromApiStats])

    useEffect(() => {
        if(SeasonFromApiStats && (currentSeasonIsSet || lastSeasonIsSet)) {
            addSeasonToState()
        }
    }, [SeasonFromApiStats, currentSeasonIsSet, lastSeasonIsSet])

    useEffect(() => {
        calculateReputationScoreHandler();
    }, [SeasonStats])

    useEffect(() => {
        if(SeasonStats && reputationScore) {
            giveStatNote('kda', 'KDA', Kda, [0, 0.3, 0.5, 0.6, 0.7, 1, 1.2, 1.35, 1.5, 2.5, 3.5, 5]);
            giveStatNote('repWPAI', 'Score WPAI', reputationScore, [0, 0.3, 0.5, 1, 1.2, 1.5, 2, 2.5, 3, 3.5, 5]);
            giveStatNote('longestKill', 'Longest Kill', LongestKill, [0, 100, 200, 300, 500, 600, 700, 850, 1000, 1100, 1300, 1500]);
            giveStatNote('Revives % par game', 'Revive percentage per game', (Revives / TotalGames) * 100, [0, 5, 10, 15, 19, 20, 21, 22, 25, 30,35]); 
            giveStatNote('Suicides % / game','Suicide percentage per game', (Suicides / TotalGames) * 100, [0, 0.5, 0.7, 0.9, 1, 1.5, 2, 2.5, 3, 3.5]);
            giveStatNote('tk % / game','Teamkill percentage per game', Math.round(((Tks * 100) / TotalGames) * 100) / 100, [0, 0.25, 0.5, 0.8, 1, 1.5, 1.7, 2, 2.5, 3, 3.5]);
            giveStatNote('Heures par jour', 'Hours played per day', Math.round((HoursPlayed / DaysPlayed) * 100) / 100, [0, 0.5, 0.8, 0.9, 1, 1.5, 1.7, 1.9, 2, 2.5, 3]);
            giveStatNote('Games par heures', 'Average games per hour', Math.round((TotalGames / HoursPlayed) * 100) / 100, [2, 3, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5]);
            
            setFinalProfiles(checkProfilesStats())
        }
    },[SeasonStats, DaysPlayed])

    function checkSeasonProfile() {
        for (let i = 0; i < profilesPUBG.length; i++) {
            if (props.playerName === profilesPUBG[i].name) {
                if(props.isCurrent) {
                    if(!profilesPUBG[i].currentSaison) {
                        setSeasonToSet(true)
                    }
                } else {
                    if(!profilesPUBG[i].lastSaison) {
                        setSeasonToSet(true)
                    }
                }
            }
        }
    }
    function modifyProfile() {
        if(SeasonFromApiStats) {
            let allProfiles = profilesPUBG;
            for (let i = 0; i < allProfiles.length; i++) {
                if (props.playerName === allProfiles[i].name) {
                    if(props.isCurrent) {
                        allProfiles[i].currentSaison = SeasonFromApiStats
                        setCurrentSeasonIsSet(true)
                    } else {
                        allProfiles[i].lastSaison = SeasonFromApiStats
                        setLastSeasonIsSet(true)
                    }
                }
            }

            localStorage.setItem("Profiles-ureg-pubg", JSON.stringify(allProfiles));
        }
    }

    function addSeasonToState() {
        let playerProfile;
        let StorageProfiles = JSON.parse(localStorage.getItem("Profiles-ureg-pubg"))

        for (let i = 0; i < StorageProfiles.length; i++) {
            if (props.playerName === StorageProfiles[i].name) {
                if(props.isCurrent) {
                    playerProfile = StorageProfiles[i].currentSaison
                    setCurrentSeasonIsSet(true)
                } else {
                    playerProfile =  StorageProfiles[i].lastSaison
                    setLastSeasonIsSet(true)
                   }
            }
        }

        setSeasonStats(playerProfile)
    }

    function giveStatNote(stat,title,statVal,levels) {
        let highestNote = 0;
        let statObject = {
          name: stat,
          title: title,
          baseVal: statVal,
          highestNote: highestNote,
          diff : null,
          diffType: null
        };
        const newNotes = StatsNotes;
        let noteExist = false;
  
        if(statVal && !isNaN(statVal)) {
            // On boucle dans les valeurs pour définir son niveau
            for (let i = 0; i <= levels.length; i++) {
                if(statVal > levels[i]) {
                    highestNote = (i + 1);
                }
            }
            
            if(newNotes.length > 0) {
                // On check sur la stat existe déjà dans le tableau de Notes pour la remplacer
                for (let i = 0; i < newNotes.length; i++) {
                    if(stat === newNotes[i].name) {
                        newNotes[i].highestNote = highestNote;
                        noteExist = true;

                        if((levels.length - highestNote) < (0 + highestNote)) {
                            newNotes[i].diff = (levels.length - highestNote);
                            newNotes[i].diffType = "Top";
                        } else {
                            newNotes[i].diff = (0 + highestNote);
                            newNotes[i].diffType = "Bottom"
                        }
                    }
                }
            }
            
            if(noteExist === false) {
                statObject.highestNote = highestNote;
                
                if((levels.length - highestNote) < (0 + highestNote)) {
                    statObject.diff = (levels.length - highestNote);
                    statObject.diffType = "Top";
                } else {
                    statObject.diff = (0 + highestNote);
                    statObject.diffType = "Bottom";
                }

                newNotes.push(statObject)
            }

            newNotes.sort((a, b) => a.diff - b.diff);

            setStatsNotes(newNotes);
        }
    }

    function checkProfilesStats() {
        if(StatsNotes) {
            const nbProfiles = 4;
            const stats = StatsNotes;
            const profiles = Profiles;
            
            let resultProfiles = stats.slice(0,nbProfiles).map(objStats => {
                let objProfiles = profiles.find(obj => (obj.stat === objStats.name) && (obj.type === objStats.diffType));
                return {...objStats, ...objProfiles};
              });
              
              for (let i = 0; i < resultProfiles.length; i++) {
                resultProfiles[i].desc = resultProfiles[i].desc.replace('PLAYERNAME', props.playerName)
              }
            
              return(resultProfiles);
        }
    }

    function calculateReputationScoreHandler() {
        if(!SeasonStats) return;
        let winRate = 0;
        let killsPerMatch = 0;
        let killDeathRatio = 0;
        let roundsPlayed = 0;
        let suicidesDone = 0;
        let teamMatesKilled = 0;
        let totalGames = 0;
        let longestKill = 0;
        let hoursPlayed = 0;
        let daysPlayed = 0;
        let revives = 0;
        let gameModes = 0;
        let totalWins = 0;
        let totalTops = 0;
        let totalKills = 0;
        let totalAssists = 0;
        let totalLosses = 0;
        let totalDamages = 0;
        let damagesPerKill = 0;
        let swimDistance = 0;
        let walkDistance = 0;
        let rideDistance = 0;
    
       // Iterating through all the game modes
       Object.keys(SeasonStats.data.attributes.gameModeStats).forEach(gameMode => {
        if(SeasonStats.data.attributes.gameModeStats[gameMode].roundsPlayed !== 0) {
            totalWins = totalWins + (SeasonStats.data.attributes.gameModeStats[gameMode].wins);
            totalTops = totalTops + (SeasonStats.data.attributes.gameModeStats[gameMode].top10s);
            totalAssists = totalAssists + (SeasonStats.data.attributes.gameModeStats[gameMode].assists);
            totalKills = totalKills + (SeasonStats.data.attributes.gameModeStats[gameMode].kills);
            totalDamages = totalDamages + (SeasonStats.data.attributes.gameModeStats[gameMode].damageDealt);
            totalLosses = totalLosses + (SeasonStats.data.attributes.gameModeStats[gameMode].losses);
            totalTops = totalTops + (SeasonStats.data.attributes.gameModeStats[gameMode].top10s);
            killsPerMatch = killsPerMatch + (SeasonStats.data.attributes.gameModeStats[gameMode].kills / SeasonStats.data.attributes.gameModeStats[gameMode].roundsPlayed);
            totalGames = totalGames + (SeasonStats.data.attributes.gameModeStats[gameMode].roundsPlayed);
            roundsPlayed = roundsPlayed + (SeasonStats.data.attributes.gameModeStats[gameMode].roundsPlayed);
            suicidesDone = suicidesDone + (SeasonStats.data.attributes.gameModeStats[gameMode].suicides);
            teamMatesKilled = teamMatesKilled + (SeasonStats.data.attributes.gameModeStats[gameMode].teamKills);
            hoursPlayed = hoursPlayed + (SeasonStats.data.attributes.gameModeStats[gameMode].timeSurvived);
            daysPlayed = daysPlayed + (SeasonStats.data.attributes.gameModeStats[gameMode].days);
            rideDistance = rideDistance + (SeasonStats.data.attributes.gameModeStats[gameMode].rideDistance);
            swimDistance = swimDistance + (SeasonStats.data.attributes.gameModeStats[gameMode].swimDistance);
            walkDistance = walkDistance + (SeasonStats.data.attributes.gameModeStats[gameMode].walkDistance);
            gameModes = gameModes + 1;
        }
        if(gameMode !== "solo" || gameMode !== "solo-fpp")
            revives = revives + (SeasonStats.data.attributes.gameModeStats[gameMode].revives);
        
        if(longestKill < SeasonStats.data.attributes.gameModeStats[gameMode].longestKill) {
            longestKill = SeasonStats.data.attributes.gameModeStats[gameMode].longestKill;
        }
    });

        let swimDistancePerGame = Math.round((swimDistance / totalGames) * 100) / 100;
        let walkDistancePerGame = Math.round((walkDistance / totalGames) * 100) / 100;
        let rideDistancePerGame = Math.round((rideDistance / totalGames) * 100) / 100;

       
        damagesPerKill = (totalDamages / totalKills);
        killDeathRatio = (totalKills + totalAssists) / totalLosses;
        winRate = (totalWins / totalGames);

        setSwimPerGame(swimDistancePerGame);
        setWalkPerGame(walkDistancePerGame);
        setRidePerGame(rideDistancePerGame);
        setDamagesPerKill(damagesPerKill);
        setKda(killDeathRatio);
        setDamagesPerKill( totalDamages / totalKills)
        setSuicides(suicidesDone);
        setTks(teamMatesKilled);
        setTop10s((totalTops / totalGames) * 100)
        setWinRate((winRate) * 100);
        setRevives(revives);
        setLongestKill(longestKill);
        setTotalGames(totalGames);
        setKda(killDeathRatio);
        setHoursPlayed((hoursPlayed / 3600));
        setDaysPlayed(daysPlayed);


        // Normalize by the number of game modes for rep
        winRate /= gameModes;
        killsPerMatch /= gameModes;
        killDeathRatio /= gameModes;
        suicidesDone /= gameModes;
        teamMatesKilled /= gameModes;
    
        // You can use different weights for each factor or not
        const reputationScore = (winRate * 1) + (damagesPerKill * .002) + (killsPerMatch * .7) + (killDeathRatio * .8)  + ((suicidesDone / totalGames) * -5) + ((teamMatesKilled / totalGames) * -10) + (((totalTops / totalGames) * 100) * .005);
        setReputationScore(reputationScore);
    }


    return (
        <>
            {SeasonStats ?
                <>
                    {SeasonStats.data && TotalGames > 3 ?
                        <>
                            {/*console.log('SeasonStats', SeasonStats)*/}
                            {FinalProfiles && 
                                <>
                                    <div className='col-12 col-lg-9'>
                                        <div className={`w-100 Season ${props.isCurrent && "CurrentSeason"} mainPro py-4 px-3 px-md-5 rounded-0 btn btn-outline-${FinalProfiles[0].alertype} disabled opacity-100`}>
                                            <Profile 
                                                profile={FinalProfiles[0].name} 
                                                statName={FinalProfiles[0].title} 
                                                statValue={FinalProfiles[0].baseVal} 
                                                type={`w-100 py-2 py-md-4`}>
                                                {FinalProfiles[0].desc}
                                            </Profile>
                                        </div>
                                    </div>

                                    <div className='closetobe d-flex justify-content-between flex-wrap mx-auto col-12 col-lg-3'>
                                        <TitreH5 className='w-100 text-center mt-2 mb-0'>This profile is close to be</TitreH5>
                                        {FinalProfiles[1] && 
                                        <Profile 
                                            profile={FinalProfiles[1].name} 
                                            statName={FinalProfiles[1].title} 
                                            statValue={FinalProfiles[1].baseVal} 
                                            type={`btn btn-outline-${FinalProfiles[1].alertype} w-100 disabled rounded-0 overflow-hidden mt-2 mx-auto mx-md-0 p-1`}>
                                            {/*FinalProfiles[1].desc*/}
                                        </Profile>}
                                        {FinalProfiles[2] && 
                                        <Profile 
                                            profile={FinalProfiles[2].name} 
                                            statName={FinalProfiles[2].title} 
                                            statValue={FinalProfiles[2].baseVal} 
                                            type={`btn btn-outline-${FinalProfiles[2].alertype} w-100 disabled rounded-0 overflow-hidden mt-2 mx-auto mx-md-0 p-1`}>
                                            {/*FinalProfiles[2].desc*/}
                                        </Profile>}
                                        {FinalProfiles[3] && 
                                        <Profile 
                                            profile={FinalProfiles[3].name} 
                                            statName={FinalProfiles[3].title} 
                                            statValue={FinalProfiles[3].baseVal} 
                                            type={`btn btn-outline-${FinalProfiles[3].alertype} w-100 disabled rounded-0 overflow-hidden mt-2 mx-auto mx-md-0 p-1`}>
                                            {/*FinalProfiles[3].desc*/}
                                        </Profile>}
                                    </div>
                                </>
                            }
                        </>
                        :
                        <div className='overlay-light p-2 mt-4 mb-5'>
                            <TitreH5 className="w-100 text-center">{props.playerName} has not enough Normal games played for this season</TitreH5>
                            <p className="w-100 text-center"><small>Due to the lack of datas, no Profile can be set</small></p>
                        </div>
                    }
                </>
            :
                <div>
                    <img src='https://media2.giphy.com/media/dAcUSqS5cT5oIt75jS/giphy.gif?cid=790b7611b213d5584648958f8b02cb18291f5137a0408421&rid=giphy.gif&ct=s' alt="loader" />
                </div>
            }
        </>
    );
}

export default SeasonsProfiles;